import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import navLogo from "../../Assets/nav-logo.png"


const DeshboardLogin = () => {
  const [keySession, setkeySession]= useState(false)

  const getKey= localStorage.getItem("IsEmailLogin")
  const lastLoginTime=localStorage.getItem("loginTime")
  console.log("getKey", getKey)
  //code for key false  after a specific time

  useEffect(()=>{
    function checkingkey(){
      if(getKey){
        const currentTime= new Date().getTime()
        console.log("currentTime",currentTime)
        const sessionExpirationTime =  1*60*1000;
        console.log("last login time",parseInt(lastLoginTime))  // 3mint milestone
        if((currentTime-parseInt(lastLoginTime)) < sessionExpirationTime){
         setkeySession(true)
        }
        else{
         setkeySession(false)
         localStorage.setItem("IsEmailLogin",false)
       };
      }  else{
       setkeySession(false)
       
     }
   
    } 

    checkingkey()
  },[getKey,lastLoginTime]);
   
  return (
    <div class="w-[100%]">
      <div className="flex flex-col lg:flex-row">
        <div
          className="bg-cover w-[100%] lg:w-[60%] bg-center h-[60vh] lg:h-screen filter-[brightness(0.4)] relative "
          style={{
            backgroundImage:
              'linear-gradient(#23171769, #181810), url("/images/desImg.png")',
          }}
        >
          <div className="p-[12px]">
            <img
              src={navLogo}
              alt="#"
              className="w-[223px] h-[76px]"
            />
          </div>
          <div className=" translate-none lg:translate-y-[-378%] leading-[30px] lg:leading-[35px] flex justify-center lg:absolute lg:left-[20px] lg:bottom-0">
            {/* <p className="text-[26px] hidden lg:block lg:text-[32px] font-normal not-italic text-[#fff] mt-[30px] lg:mt-[0] pl-[25px] lg:pl-[0] pe-[25px] lg:pe-[0]">
              <span className="text-[#A8C523] text-[36px] not-italic tracking-[0.36px] uppercase font-semibold	">
                Join us in connecting  
              </span>
              <br /> to a world of possibilities and unlocking
              <br />
              the door to your professional success.
            </p> */}
            {/* >>>>>>>>> */}
            <p className="text-[22px] block lg:hidden lg:text-[32px] font-normal not-italic text-[#fff] mt-[30px] lg:mt-[0] pl-[25px] lg:pl-[0] pe-[25px] lg:pe-[0]">
              <span className="text-[#A8C523] text-[25px] not-italic tracking-[0.36px] uppercase font-semibold	">
                Join us in connecting  
              </span>
              <br /> to a world of possibilities and unlocking
              the door to your professional success.
            </p>
          </div>
        </div>
        <div className="w-[100%] lg:w-[40%] h-[40vh] lg:h-screen bg-[#1E243B] flex flex-col items-center justify-center relative">
          <p className="text-[#fff] text-[28px] sm:text-[32px] font-semibold not-italic">
            Get started
          </p>
          <div className="flex gap-3 mt-[10px]">
            <Link to={`${keySession? "/homePage" : "/emailLogin" }`}>
              <button className="w-[130px] lg:w-[200px] h-[48px] bg-[#A8C523] font-semibold text-[18px] rounded-[8px]">
                Login
              </button>
            </Link>
            
          
            {/* <Link to="/recruiteJobSeeker">                                                                                    //signup button
              <button className="w-[130px] lg:w-[200px] h-[48px] bg-[#A8C523] font-semibold text-[18px] rounded-[8px]">
                Signup
              </button>
            </Link> */}
          </div>
          {/* <div className="text-[#fff] flex absolute bottom-[14px] items-center gap-[8px] text-[17px] font-normal	">
            <p>Privacy Policy</p>
            <p className="w-[1px] h-[20px] bg-[#fff]"></p>
            <p>Term and Condition</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DeshboardLogin;




// url("/images/des.png")