import React, { useReducer, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logoImg from "../../Assets/nav-logo.png"
import emailLogo from "../../Assets/Email-login.png"
import {
  onFocusOut,
  onInputChange,
  UPDATE_FORM,
  validateInput,
} from "../EmailLoginForm.js"

const initialState = {
  email: { value: "", touched: false, hasError: true, error: "" },
  password: { value: "", touched: false, hasError: true, error: "" },
  isFormValid: false,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      const { name, value, hasError, error, touched, isFormValid } =
        action.data;
      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched },
        isFormValid,
      };
    default:
      return state;
  }
};

const EmailLogin = () => {
  const [formState, dispatch] = useReducer(formReducer, initialState);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const confirmValidity = (formState, dispatch) => {
    let isFormValid = true;
    for (let name in formState) {
      const item = formState[name];
      const { value } = item;
      const { hasError, error } = validateInput(name, value, formState);
      if (hasError) {
        isFormValid = false;
      }
      if (name) {
        dispatch({
          type: UPDATE_FORM,
          data: {
            name,
            value,
            hasError,
            error,
            touched: true,
            isFormValid,
          },
        });
      }
    }
  };

  const currentTime= new Date().getTime()
  console.log("currentTime",currentTime)

  const emailApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: formState.email.value,
      password: formState.password.value,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.letshireme.com/v1/login/email", requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
          
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
          throw new Error("Email not found.");
        }
      })
      .then((result) => {
        console.log("result>>>", result.message);
        navigate("/homepage");
        localStorage.setItem("IsEmailLogin",true);
        localStorage.setItem("loginTime",currentTime)
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  console.log("seterror>>>>>>", error);

  const onSubmit = (e) => {
    e.preventDefault();
    confirmValidity(formState, dispatch, validateInput);
    if (formState.isFormValid) {
      console.log("output>>>>>", formState);
      setLoading(true);
      emailApi();
    }
  };

  return (
    <>
      <div className="flex items-center flex-col justify-start w-[100%] sm:h-[100vh]">
        <div className="p-[12px] w-[100%]">
          <Link to="/">
            <img
              src={logoImg}
              alt="#"
              className="w-[176px] sm:w-[223px] h-[60px] sm:h-[76px]"
            />
          </Link>
        </div>
        <div className="relative flex justify-center w-[100%] max-[991px]:px-[15px] md:py-[60px] overflow-y-auto">
          <div className="max-w-[570px] w-[100%]">
            <div className="rounded-[48px] box-shadow: 0px_4px_48px_-6px rgba(0, 0, 0, 0.09) max-w-[480px] p-[35px] mx-auto max-[480px]:px-[15px] max-[767px]:rounded-[20px] pb-[60px]">
              <div className="text-center block text-black text-[32px] leading-[45px] font-semibold mb-[15px]">
                Log<span className="text-[#A8C523]">in</span>
              </div>
              <img
                className="mx-auto my-[20px] w-[55px] sm:w-[100px] h-[55px] sm:h-[100px]"
                src={emailLogo}
                alt="signup"
                width={50}
                height={100}
                quality={100}
              />
              <form className="flex flex-col gap-[20px] sm:gap-[30px] max-[991px]:flex-col w-[100%] mx-auto max-[480px]:!w-[100%] mt-[20px] sm:mt-[40px]">
                <div className="w-[100%]">
                  <span>
                    <input
                      id="email"
                      placeholder="E-mail"
                      className={classNames(
                        "flex max-[991px]:w-[100%] text-[black] px-[22px]  rounded-[10px] max-[767px]:max-w-[100%]  max-[767px]:bg-[20px_center] max-[767px]:px-[15px] h-[50px] bg-transparent border border-solid border-[#6F7780] max-[767px]:max-w-[100%  h-[100%] placeholder:text-[#6F7780] placeholder:bg-transparent placeholder:text-[18px] placeholder:font-normal placeholder:leading-[28px] relative focus:outline-[0] w-full max-w-[408px] text-[#6F7780] focus:bg-transparent",
                        formState.email.touched && formState.email.hasError
                          ? "border-red-600"
                          : "border-off-white"
                      )}
                      value={formState.email.value}
                      onChange={(e) => {
                        onInputChange(
                          "email",
                          e.target.value,
                          dispatch,
                          formState
                        );
                      }}
                      onBlur={(e) => {
                        onFocusOut(
                          "email",
                          e.target.value,
                          dispatch,
                          formState
                        );
                      }}
                    ></input>
                  </span>
                  {formState.email.touched && formState.email.hasError && (
                    <div className="info-text mt-3 mb-[12px] ml-2 text-red-600">
                      {formState.email.error}
                    </div>
                  )}
                  {error && (
                    <div className="text-red-500 text-sm mt-2 text-center">
                      {error}
                    </div>
                  )}
                  <span>
                    <input
                      autoComplete="off"
                      id="password"
                      type="password"
                      maxLength={8}
                      placeholder="Password"
                      className={classNames(
                        "flex max-[991px]:w-[100%] text-[black] px-[22px] mt-[10px] rounded-[10px] max-[767px]:max-w-[100%] max-[767px]:bg-[20px_center] max-[767px]:px-[15px] h-[50px] bg-transparent border border-solid border-[#6F7780] max-[767px]:max-w-[100%  h-[100%] placeholder:text-[#6F7780] placeholder:bg-transparent placeholder:text-[18px] placeholder:font-normal placeholder:leading-[28px] relative focus:outline-[0] w-full max-w-[408px] text-[#6F7780] focus:bg-transparent",
                        formState.password.touched &&
                          formState.password.hasError
                          ? "border-red-600"
                          : "border-off-white"
                      )}
                      value={formState.password.value}
                      onChange={(e) => {
                        onInputChange(
                          "password",
                          e.target.value,
                          dispatch,
                          formState
                        );
                      }}
                      onBlur={(e) => {
                        onFocusOut(
                          "password",
                          e.target.value,
                          dispatch,
                          formState
                        );
                      }}
                    />
                  </span>
                  {formState.password.touched &&
                    formState.password.hasError && (
                      <div className="info-text mt-3 ml-2 text-red-600">
                        {formState.password.error}
                      </div>
                    )}
                </div>
                <button
                  className="gap-2 ow w-[100%] h-[50px] px-[15px] bg-[#1E243B] rounded-[10px] flex justify-center items-center max-[480px]:w-[100%] max-[480px]:ml-[0]"
                  type="submit"
                  onClick={onSubmit}
                >
                  <span className="text-[#fff] font-semibold text-[18px] leading-[22px]">
                    {loading ? "Login..." : "Submit"}
                  </span>
                </button>
                {/* <div className="text-[#6F7780] ml-[12px] text-center text-[16px] sm:text-[18px] font-medium ">                // sign up button hide
                  Don't have an account ?{" "}
                  <Link to="/recruiteJobSeeker">
                    <span className="text-[#A8C523] font-semibold">
                      Sign up
                    </span>
                  </Link>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailLogin;
