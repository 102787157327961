import { useState } from "react"
import { RightArrow } from "./Icons/RightArrow.js"
import { AffiliateList } from "./AffliatesList.js";
export function ApiResponseResult({errorNo, errorName}){

const [openApiResopnse, setOpenApiResopnse]= useState("")

let color;
let bgcolor;
let textColor;
if(errorNo === 200){
    bgcolor = "bg-[#1D812712]";
    color = "green";
    textColor="text-[#1D8127]"
} else{
    bgcolor = "bg-[#d41f1c12]";
    color = "red";
    textColor= "text-[#D41F1C]"
};




    return(
        <div className="w-full">
            <button className={`flex items-center w-full pl-[10px] mb-[4px] h-[40px] ${bgcolor} box-border border-lime-500 ${textColor}`}
                onClick={() => setOpenApiResopnse(!openApiResopnse)}>
                <RightArrow color={color} stateOpenApiResopnse={openApiResopnse}/><span className="font-bold">{errorNo}</span>&nbsp; {errorName}   
            </button>
            {openApiResopnse &&
            <AffiliateList/>
            }
        </div>
        
    )
}