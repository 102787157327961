import {Bookmark} from "./Icons/Bookmark.js"
import SidebarOption from "./SidebarOption.js"


 export default function SideBar(){
    return(
        <div>
            <div className="max-w-[260px] bg-[#FAFAFA] sticky top-[0px] mt-[5px]">
                <div className="max-w-[220px] flex justify-center border-b-2 mx-[20px] pt-[8px] ">
                     <Bookmark/>
                     <input className="bg-[#FAFAFA] outline-none "
                            placeholder="Search..." />
                     <p>X</p>
                </div>
                <div className="h-[100vh] overflow-x-auto">
                  
                    <SidebarOption title="Accounts Info"/>
                    <SidebarOption title="Affiliate"/>
                    <SidebarOption title="Appointment"/>
                    <SidebarOption title="Campaign"/>
                    <SidebarOption title="Company"/>
                    <SidebarOption title="Contact"/>
                    <SidebarOption title="E-Commerce"/>
                    <SidebarOption title="Email"/>
                    <SidebarOption title="Email-Address"/>
                    <SidebarOption title="File"/>
                    <SidebarOption title="local"/>
                    <SidebarOption title="Merchant"/>
                    <SidebarOption title="Note"/>
                    <SidebarOption title="Opportunity"/>    
                    <SidebarOption title="Product"/>
                    <SidebarOption title="Rest Hooks"/>
                    <SidebarOption title="Setting"/>
                    <SidebarOption title="Tags"/>
                    <SidebarOption title="User Info"/>
                    <SidebarOption title="Users"/>
                    
                </div>
                <div className="max-w-full border-t-2 h-[full] fixed bottom-0  z-50 bg-[#FAFAFA] px-[65px] ">
                    <ul className=" ">
                        <li className="text-[12px] flex justify-center py-[10px]">
                            <label>
                                API docs by Redacly
                            </label>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    )
}