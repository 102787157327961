
import logoImg from "../Assets/nav-logo.png"


export default function Header(){
    return(
        <header className="w-full font-[13px] h-[80px] flex justify-between p-[]">
          <div className="max-w-full mx-[15px] my-[5px]" >
              <img src={logoImg} alt="img" className="min-w-[200px] h-[75px] border-none"/>
          </div>
            {/* <div className="w-[60%]"> 
                <a className="mt-[10px] mr-[30px] text-[#4967ec] pointer flex justify-end" href="">Developer Accounts </a>
                <nav className=" ">
                    <ul className="flex justify-between mr-[30px] ">
                        <li>
                            <a href="">Home</a>
                        </li>
                        <li>
                            <a href="">Documentation</a>
                        </li>
                        <li>
                            <a href="">Resources</a>
                        </li>
                        <li>
                            <a href="">Get Support</a>
                        </li>
                    </ul>
                </nav>
          </div>       */}
        </header>
    )

};  