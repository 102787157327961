 import Layout from "../Layout.js"
 import { Accountinfo } from "./AccountInfo.js";
 
//  import Login from './Login.js';


function HomePage () {
  return (
    
     <Layout className="overflow-y-hidden w-full">
        <div className="max-w-[100%] h-[px] p-[40px]">
             <h1 className='text-[26px]'>keap Rest API(1.70.06.650428)</h1>
             <div className="flex mt-[14px] mb-[14px] items-center">
               <p className="">Download OpenAI secification:</p>
               <button className="ml-[7px] px-[8px] h-[31px] pointer box-border border-[2px] border-stone-500">Download</button>
             </div>
             <p>Terms of Service</p>
       </div> 
       <div className="flex flex-col w-full ">
         <Accountinfo/>
       </div>
       {/* <Login/> */}
       </Layout>
    
  );
}

export default HomePage;


