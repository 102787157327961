

export function RightArrow({ color="green", stateOpenApiResopnse }){
    
    return(
          <svg class={`w-[19.5px] h-[19.5px] fill current items-center ${stateOpenApiResopnse? " ":"rotate-[270deg]"}`} version="1.1" viewBox="0 0 24 24" x="0" xmlns="http://www.w3.org/2000/svg" y="0" aria-hidden="true">
               <polygon points="17.3 8.3 12 13.6 6.7 8.3 5.3 9.7 12 16.4 18.7 9.7 " fill={color}></polygon>
          </svg>
    )
};


<svg class="sc-dIsUp lbZXRr" version="1.1" viewBox="0 0 24 24" x="0" xmlns="http://www.w3.org/2000/svg" y="0" aria-hidden="true"><polygon points="17.3 8.3 12 13.6 6.7 8.3 5.3 9.7 12 16.4 18.7 9.7 "></polygon></svg>