
// import React, {  useState } from 'react';
// import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  // const [ isAuthenticated, setIsAuthenticated ]= useState({OnUpdate})
  const isAuthenticated = localStorage.getItem('IsEmailLogin');
    // console.log("isauthenticated1", OnUpdate)
 
    // useEffect(()=>{
    //   setIsAuthenticated(OnUpdate)
    // }
    // ,[OnUpdate])
    // setIsAuthenticated(localStorage.getItem("IsEmailLogin"))
    
    
  // console.log("isAuthenticated2", isAuthenticated)
  if (!isAuthenticated) {
    return <Navigate to="/emailLogin" />
  }

return children;

};

export default PrivateRoute;


