import { RightArrow } from "./Icons/RightArrow.js"
import { useState } from "react"
import { OptionOpenSidebar } from "./Sidebar/OptionOpenSidebar.js"



 export default function SidebarOption( {title} ){
    
    const [isOpen, setIsOpen] = useState("")
    console.log("isopen", isOpen)

    
    // const urlParms= new URLSearchParams (window.location.search);                 // add the key and vaule to the url
    //    urlParms.set("hand", 123)
    //    window.location.search=urlParms
    

    
    
    return(
        <div className="w-full">
            <ul>
                <li>
                    <label className="py-[15px] pl-[20px] pr-[20px] flex justify-between items-center cursor-pointer hover:bg-[#E1E1E1]">
                        <button 
                            onClick={()=> setIsOpen(!isOpen)}>{title}
                        </button>
                        <RightArrow stateOpenApiResopnse={isOpen}/> 
                    </label>     
                </li>
            </ul>
            {isOpen &&
            <OptionOpenSidebar/>
            }
            
        </div>
    )
 };

