
 export function OptionOpenSidebar(){
    return(
        <>
            <div className="py-[8px] px-[20px] flex items-start hover:bg-[#EDEDED] cursor-pointer">
                <button className="mt-[6px] mr-[6px] px-[10px] text-[10px] bg-[#2F8132] rounded-[3px] text-white font-medium">GET</button>
                <button className="font-[10px] text-start">Retrieve account profile</button>
            </div>
             <div className="py-[8px] px-[20px] flex items-start hover:bg-[#EDEDED] cursor-pointer">
                <button className="mt-[6px] mr-[6px] px-[10px] text-[10px] bg-[#95507C] rounded-[3px] text-white font-medium">PUT</button>
                <button className="font-[10px] text-start">Updates an account profile</button>
            </div> 
        </>
    )
};            