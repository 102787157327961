import { RightArrow } from "./Icons/RightArrow"


export function ApiResponseFormat(){
    

    return(
        <div className=" w-full bg-[#263238] p-[40px] ">
            <div className="flex items-center">
                <div className="py-[10px] w-full pr-[30px] pl-[20px]  bg-black flex items-center">
                    <button className=" py-[px] px-[10px] pointer text-white bg-green-800">GET</button>
                    <span className="text-white">/v1/account/profile</span>
                </div>
                <div className="bg-black  h-[44px] pt-[12px] pr-[10px]">
                   <RightArrow color="white"/>
                </div>
            </div>
            
            <div>
                <p className="text-white my-[15px] font-bold">Response Sample</p>
                <div>
                    <button className="bg-black text-green-800 rounded-[5px] py-[7px] px-[18px]">200</button>

                    <button className="bg-black text-green-800 rounded-[5px] py-[7px] px-[18px] ml-[10px]">401</button>
                    <button className="bg-black text-green-800 rounded-[5px] py-[7px] px-[18px] ml-[10px]">402</button>
                    <button className="bg-black text-green-800 rounded-[5px] py-[7px] px-[18px] ml-[10px]">404</button>
                </div>
                <div className="bg-black     mt-[5px] p-[20px] ">
                  <p className="text-white font-semibold ">Content type</p>
                  <div className="p-[10px] bg-[#263238] text-white mb-[11px]">
                       <p>application/json</p>                    
                  </div>
                  <div className="flex justify-end ">
                  <button className="bg-black text-white rounded-[5px] py-[7px] px-[18px] ">Copy</button>
                    <button className="bg-black text-white rounded-[5px] py-[7px] px-[18px] ml-[5px]">Expand all</button>
                    <button className="bg-black text-white rounded-[5px] py-[7px] px-[18px] ml-[5px]">Collapse all</button>
                  </div>
                  <div className="h-[638px]">
                      <p className="text-white">Api Data</p>
                  </div>

                </div>
                
            </div>
        </div>
        
    )
};