
import { ApiResponseFormat } from "../ApiResponseFormat.js"

import { ApiResponseResult } from "../ApiResponseResult.js"

export function Accountinfo(){

 return(
   <>
      <div className="w-full p-[40px]">
         <h1 className="text-[26px]">Account Info</h1>
      </div>
      
      <p className="pl-[40px] mt-[14px]">Account Info-Endpoint</p> 
      <div className="w-full xl:flex">
         <div className=" w-full p-[40px]">
            <h2 className="text-[22px] mb-[20px]">Retrieve account Profile</h2>
            <p>Retrieve profile/Company Info for an account</p>
            <h3 className="mt-[55px] mb-[20px] text-[18px]">Responses</h3>
            <div className="w-full">
               <ApiResponseResult errorNo={200} errorName={"Ok"}/>
               <ApiResponseResult errorNo={401} errorName={"Unauthorized"}/>
               <ApiResponseResult errorNo={403} errorName={"Forbidden"}/>
               <ApiResponseResult errorNo={404} errorName={"Not Found"}/>
            </div>
            
         </div> 
         <ApiResponseFormat/>       
      </div>
      <div className="p-[40px]">
        <h2 className="text-[22px] mb-[20px]">Updates an account profile</h2>
        <p className="">updates profile/company Info for an account</p>
      
      </div>
      <div className="p-[40px]">
        <h2 className="text-[22px] mb-[20px]">Updates an account profile</h2>
        <p className="">updates profile/company Info for an account</p>
      
      </div>
      <div className="p-[40px]">
        <h2 className="text-[22px] mb-[20px]">Updates an account profile</h2>
        <p className="">updates profile/company Info for an account</p>
      
      </div>
      <div className="p-[40px]">
        <h2 className="text-[22px] mb-[20px]">Updates an account profile</h2>
        <p className="">updates profile/company Info for an account</p>
      
      </div>
      <div className="p-[40px]">
        <h2 className="text-[22px] mb-[20px]">Updates an account profile</h2>
        <p className="">updates profile/company Info for an account</p>
      
      </div>
      <div className="p-[40px]">
        <h2 className="text-[22px] mb-[20px]">Updates an account profile</h2>
        <p className="">updates profile/company Info for an account</p>
      
      </div>
      
   </>
 )
};