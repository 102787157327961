import { RightArrow } from "./Icons/RightArrow"
export function AffiliateList(){
    return(
        <>
           <div className="w-full p-[10px]">
                <p className="border-b-2 my-[20px] text-gray-400">REQUEST BODY SCHEMA:  application/json</p>
                <p className="mt-[20px]">accountInfo</p>
                <table className="w-full text-[12px]">
                    <tbody>
                        <tr className="border-l-2 border-gray-500">
                            <td className="w-[40%]  py-[10px] pr-[10px] flex">
                                <span className="mr-[10px]">- </span>
                                <button className="">Address</button>
                                <div className="mt-[4px] ">
                                <RightArrow color="black"/>
                                </div>
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400">
                            <p>object (contactaddress)</p>
                            </td>
                        </tr>
                        <tr className=" border-l-[2px] border-gray-500">
                            <td className="w-[40%] py-[10px] pr-[10px] flex">
                                <span className="  mr-[10px]">- </span>
                                <button className="">bussiness_goals</button>
                            
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400 py-[10px]">
                                <p>Array of strings</p>
                                <p>The goals of business,ie. Grow Business, convert more leads</p>
                            </td>
                        </tr>
                        <tr className="border-l-2 border-gray-500">
                            <td className="w-[40%]  py-[10px] pr-[10px] flex">
                                <span className=" mr-[10px]">- </span>
                                <button className="">business_primary_color</button>
                                
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400">
                            <p>string</p>
                            </td>
                        </tr>
                        <tr className="border-l-2 border-gray-500"> 
                            <td className="w-[40%]  py-[10px] pr-[10px] flex ">
                                <span className=" mr-[10px]">- </span>
                                <button className="">business_secondary_color</button>
                                
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400">
                            <p>string</p>
                            </td>
                        </tr>
                        <tr className="border-l-2 border-gray-500"> 
                            <td className="w-[40%]  py-[10px] pr-[10px] flex ">
                                <span className=" mr-[10px]">- </span>
                                <button className="">business_type</button>
                                
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400">
                            <p>string</p>
                            <p className="text-[14px]">The type of the business</p>
                            </td>
                        </tr>
                        <tr className="border-l-2 border-gray-500"> 
                            <td className="w-[40%]  py-[10px] pr-[10px] flex ">
                                <span className=" mr-[10px]">- </span>
                                <button className="">currency code</button>
                                
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400">
                            <p>string</p>
                            </td>
                        </tr>
                        <tr className="border-l-2 border-gray-500"> 
                            <td className="w-[40%]  py-[10px] pr-[10px] flex ">
                                <span className=" mr-[10px]">- </span>
                                <button className="">email</button>
                                
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400">
                            <p>string</p>
                            </td>
                        </tr>
                        <tr className="border-l-2 border-gray-500"> 
                            <td className="w-[40%]  py-[10px] pr-[10px] flex ">
                                <span className=" mr-[10px]">- </span>
                                <button className="">language_tag</button>
                                
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400">
                            <p>string</p>
                            </td>
                        </tr>
                        <tr className="border-l-2 border-gray-500"> 
                            <td className="w-[40%]  py-[10px] pr-[10px] flex ">
                                <span className=" mr-[10px]">- </span>
                                <button className="">logo_url</button>
                                
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400">
                            <p>string</p>
                            </td>
                        </tr>
                        <tr className="border-l-2 border-gray-500"> 
                            <td className="w-[40%]  py-[10px] pr-[10px] flex ">
                                <span className=" mr-[10px]">- </span>
                                <button className="">name</button>
                                
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400">
                            <p>string</p>
                            </td>
                        </tr>
                        <tr className="border-l-2 border-gray-500"> 
                            <td className="w-[40%]  py-[10px] pr-[10px] flex ">
                                <span className=" mr-[10px]">- </span>
                                <button className="">phone</button>
                                
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400">
                            <p>string</p>
                            </td>
                        </tr>
                        <tr className="border-l-2 border-gray-500"> 
                            <td className="w-[40%]  py-[10px] pr-[10px] flex ">
                                <span className=" mr-[10px]">- </span>
                                <button className="">phone_ext</button>
                                
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400">
                            <p>string</p>
                            </td>
                        </tr>
                        <tr className="border-l-2 border-gray-500"> 
                            <td className="w-[40%]  py-[10px] pr-[10px] flex ">
                                <span className=" mr-[10px]">- </span>
                                <button className="">time__zone</button>
                                
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400">
                            <p>string</p>
                            </td>
                        </tr>
                        <tr className="border-l-2 border-gray-500 bor"> 
                            <td className="w-[40%]  py-[10px] pr-[10px] flex ">
                                <span className=" mr-[10px]">- </span>
                                <button className="">web sites</button>
                                
                            </td>
                            <td className="w-[60%] border-b-2 text-black-400">
                            <p>string</p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                
            </div>  
        </>
    )
}