import Header from "./Header";
import SideBar from "./SideBar";

const Layout=({children})=>{
    return(
        <>
          <Header/>        
          <main className="flex">
            <SideBar/>
            <div className="flex-col w-full">
            {children}
            </div>
          </main> 
        </>
    )
}
export default Layout;