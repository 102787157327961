import './App.css';
import { Routes, Route} from "react-router-dom";
import HomePage  from "./Components/Page/HomePage.js";
import EmailLogin from './Components/Page/EmailLogin.js';
// import MobileLogin from './Components/Page/MobileLogin.js';
import DeshboardLogin from './Components/Page/DeshboardLogin.js';
import PrivateRoute from './Components/Page/PrivateRoute.js';


function App() {
  
  
  
  return(
    <>
      <Routes>
        <Route path="/" element= {<DeshboardLogin/>}/>
        {/* <Route path="/mobilelogin" element= {<MobileLogin/>} />                  Not need of login Page through Mobile  */}
        <Route path="/emailLogin" element= {<EmailLogin/>} />
        <Route path="/homepage" element= {<PrivateRoute><HomePage /></PrivateRoute>}/>
      </Routes>
    </>
  )
};
export default App; 